<template>
  <div id="jobs-dataTables_wrapper" class="dataTables_wrapper dt-bootstrap4 col-12">
    <div id="table-header-container" class="row">
      <ul v-if="dataTablesType === 0 && !isCampaignDateRange" class="nav nav-tabs nav-tabs-underline">
        <li class="nav-item">
          <a id="cycle-nav-tab" class="nav-link table-text-header" data-toggle="tab" data-target="#cycle-jobs-container"
            href="#cycle-jobs-container">Jobs With Activity This Cycle</a>
        </li>
        <li v-show="showUnpostedTable && currentCycle" class="nav-item">
          <a id="unposted-nav-tab" class="nav-link table-text-header" data-toggle="tab"
            data-target="#unposted-jobs-container" href="#unposted-jobs-container">Available Jobs With No Activity</a>
        </li>
      </ul>
      <span v-else-if="dataTablesType === 0 && isCampaignDateRange" class="table-text-header">Jobs With This Date
        Range</span>
      <span v-else-if="dataTablesType === 1" class="table-text-header">Jobs With Activity</span>
      <span v-else-if="dataTablesType === 2" class="table-text-header">Available Jobs For Upcoming Campaign</span>
    </div>
    <!-- BEGIN: DataTable Contents -->
    <div v-if="dataTablesType === 0" id="campaign-tab-content" class="tab-content">
      <div id="cycle-jobs-container" class="col-md-12 tab-pane fade">
        <table id="campaign-job-dataTables" class="table dataTable" width="100%">
          <thead id="jobs-thead">
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Req. Name</th>
              <th>Location</th>
              <th>Spend*</th>
              <th>Spend Target <span v-html="displayInfoIcon(infoIcon.spendTargetColumn)"></span></th>
              <th>Clicks*</th>
              <!-- <th>CPC</th> -->
              <th>App <span v-html="displayInfoIcon(infoIcon.appColumn)"></span></th>
              <th>CPA*</th>
              <th>Status</th>
              <th data-orderable="false">Actions</th>
            </tr>
          </thead>
          <tbody id="jobs-tbody">
          </tbody>
        </table>
      </div>
      <div id="unposted-jobs-container" class="col-md-12 tab-pane fade">
        <table id="unposted-jobs-dataTables" class="table dataTable" width="100%">
          <thead id="jobs-thead">
            <tr>
              <th>ID</th>
              <th>Job ID</th>
              <th>Title</th>
              <th>Location</th>
              <th>Status</th>
              <th data-orderable="false">Actions</th>
            </tr>
          </thead>
          <tbody id="jobs-unposted-tbody">
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="dataTablesType === 1">
      <table id="singles-job-dataTables" class="table dataTable" width="100%">
        <thead id="jobs-thead">
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Req. Name</th>
            <th>Location</th>
            <th>Clicks</th>
            <th>App <span v-html="displayInfoIcon(infoIcon.appColumn)"></span></th>
            <th>Posted Date</th>
            <th>Expiration Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody id="jobs-tbody">
        </tbody>
      </table>
    </div>
    <div v-else-if="dataTablesType === 2">
      <table id="select-jobs-dataTables" class="table dataTable" width="100%">
        <thead id="jobs-thead">
          <tr>
            <th>ID</th>
            <th>Job ID</th>
            <th>Title</th>
            <th>Location</th>
            <th>Status</th>
            <th data-orderable="false">Actions</th>
          </tr>
        </thead>
        <tbody id="jobs-tbody">
        </tbody>
      </table>
    </div>
    <!-- END: DataTable Contents -->
    <JobDescriptionModal v-show="showJobDesc" :jobDescriptionData="jobDescriptionData" :dataTablesType="dataTablesType"
      :campaignRatio="campaignRatio" :jobDateRange="jobDateRange" :isCompanyEAEnabled="isCompanyEAEnabled"
      @close="closeJobDescModal" />
    <RepostModal v-show="showRepost" :repostData="repostData" @close="closeRepostModal" />
    <JobActionModal v-show="showJobAction" :jobActionData="jobActionData" :jobAction="jobAction"
      @close="closeJobActionModal" @confirm-post-job="confirmPostJob" @confirm-stop-job="confirmStopJob" />
    <JobActionLegacyModal v-show="showJobLegacyAction" :jobActionData="jobActionData" :jobAction="jobAction"
      @close="closeJobLegacyActionModal" @confirm-post-job="confirmPostJob" @confirm-stop-job="confirmStopJob" />
    <SpendLimitModal @update:spendLimit="handleSpendLimitUpdate" 
    v-show="showSpendLimit" :spendLimitData="spendLimitData" @close="closeSpendLimitModal" />
  </div>
</template>

<script>
import JobActionModal from './JobActionModal.vue';
import JobActionLegacyModal from './JobActionLegacyModal.vue';
import JobDescriptionModal from './JobDescriptionModal.vue';
import RepostModal from './RepostModal.vue';
import SpendLimitModal from './SpendLimitModal.vue';
import store from "../store/index";

const JobActionEnum = require('../utils/enums/JobActionEnum');

export default {
  name: 'JobsTable',
  emits: ['jobs-analytics-data', 'date-range-analytics-data'],
  components: {
    JobActionModal,
    JobActionLegacyModal,
    JobDescriptionModal,
    RepostModal,
    SpendLimitModal,
  },
  props: {
    cycleId: {
      type: String,
    },
    url: {
      type: String,
    },
    unpostedUrl: {
      type: String,
    },
    dateRangeUrl: {
      type: String,
    },
    cycle: {
      type: Object
    },
    campaignRatio: {
      type: Number
    },
    isCampaignDateRange: {
      type: Boolean
    },
    jobDateRange: {
      type: Object
    },
    dataTablesType: {
      type: Number,
      required: true
    },
    isCompanyEAEnabled: {
      type: Boolean
    }
  },
  data() {
    return {
      userState: store.state,
      jobAction: '',
      jobTable: null,
      unpostedJobTable: null,
      jobDescriptionData: null,
      jobActionData: null,
      repostData: null,
      spendLimitData: null,
      showJobDesc: false,
      showJobAction: false,
      showJobLegacyAction: false,
      showRepost: false,
      showSpendLimit: false,
      showUnpostedTable: false,
      infoIcon: {
        active: { tooltip: "'This job is live in your Programmatic campaign.'", color: 'text-dark-success' },
        activeSingles: { tooltip: "'This job is live.'", color: 'text-dark-success' },
        spendLimitHit: { tooltip: "'This job is paused because the job spend target was met or nearly met.'", color: 'text-dark-danger' },
        cycleBudgetHit: { tooltip: "'This job is paused because the cycle budget was met or nearly met.'", color: 'text-dark-danger' },
        generalPause: { tooltip: "'This job is currently paused. If you have a spend target, you can increase your target to resume. Or click the \"STOP JOB\" button and then \"POST JOB\" to reactivate your job.'", color: 'text-dark-danger' },
        onHoldCampaign: { tooltip: "'This job has been paused and is not live in your Programmatic cycle.'", color: 'text-gray-600' },
        inactiveSingles: { tooltip: "'This job is not live.'", color: 'text-gray-600' },
        inactiveCampaign: { tooltip: "'This job is not live in your Programmatic cycle.'", color: 'text-gray-600' },
        inactiveUnposted: { tooltip: "'This job has not been added to your current Programmatic cycle.'", color: 'text-gray-600' },
        inactiveDateRange: { tooltip: "'This job is not live in your Programmatic campaign.'", color: 'text-gray-600' },
        closed: { tooltip: "'This job is no longer active or accepting new applications in your Applicant Tracking System or career page.'", color: 'text-gray-600' },
        stopped: { tooltip: "'This job has been manually stopped.'", color: 'text-gray-600' },
        inProgress: { tooltip: "'This job is in the process of being posted.'", color: 'text-dark-warning' },
        pendingDelete: { tooltip: "'This job is in the process of being removed.'", color: 'text-dark-warning' },
        postedDate: { tooltip: "'Most recent posting'", color: 'text-info' },
        appColumn: { tooltip: "'Total number of applications received in selected time period.'", color: 'text-primary' },
        spendTargetColumn: { tooltip: "'Spend Target is a soft target that the Programmatic systems try to hit. Actual spend maybe over or under the target.'", color: 'text-primary' }
      }
    }
  },
  computed: {
    currentCycle() {
      return !this.cycle || this.cycle.status_id === 3 ? false : this.cycle.current_cycle;
    }
  },
  watch: {
    url: {
      async handler(newVal, oldVal) {
        if (!newVal) return;

        this.removeJobsTable();
        await this.handleJobsTable();
      },
      deep: true
    },
    dateRangeUrl: {
      async handler(newVal, oldVal) {
        if (!newVal || this.dataTablesType !== 0) return;

        this.removeJobsTable();
        await this.getDateRangeJobsTable();
      },
      deep: true
    },
    cycleId: {
      async handler(newVal) {  
        store.methods.setCurrentCycleId(newVal);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.handleJobsTable();
    $('body').tooltip({
      selector: '[data-tooltip="tooltip"]',
      trigger: 'hover'
    });

    this.tBodyEventListener('jobs-tbody');
  },
  beforeUnmount() {
    const tBody = document.getElementById('jobs-tbody');
    const unpostedTBody = document.getElementById('jobs-unposted-tbody');

    if (tBody) tBody.removeEventListener('click', this.handleJobPossibleClicks);
    if (unpostedTBody) unpostedTBody.removeEventListener('click', this.handleJobPossibleClicks);

    this.removeJobsTable();
  },
  methods: {
    removeJobsTable() {
      const campJobTable = '#campaign-job-dataTables';
      const unpostedJobTable = '#unposted-jobs-dataTables';
      const singleJobTable = '#singles-job-dataTables';
      const selectJobTable = '#select-jobs-dataTables';

      if ($.fn.DataTable.isDataTable(campJobTable)) {
        $(campJobTable).DataTable().clear();
        $(campJobTable).DataTable().destroy();
      }

      if ($.fn.DataTable.isDataTable(unpostedJobTable)) {
        $(unpostedJobTable).DataTable().clear();
        $(unpostedJobTable).DataTable().destroy();
      }

      if ($.fn.DataTable.isDataTable(singleJobTable)) {
        $(singleJobTable).DataTable().clear();
        $(singleJobTable).DataTable().destroy();
      }

      if ($.fn.DataTable.isDataTable(selectJobTable)) {
        $(selectJobTable).DataTable().clear();
        $(selectJobTable).DataTable().destroy();
      }

      $(`${campJobTable} tbody`).empty();
      $(`${unpostedJobTable} tbody`).empty();
      $(`${singleJobTable} tbody`).empty();
      $(`${selectJobTable} tbody`).empty();
    },
    campaignTableNavigation() {
      const cycleNavTab = document.getElementById('cycle-nav-tab');
      const unpostedNavTab = document.getElementById('unposted-nav-tab');
      const cycleJobsContainer = document.getElementById('cycle-jobs-container');
      const unpostedJobsContainer = document.getElementById('unposted-jobs-container');

      cycleNavTab.classList.add('active');
      unpostedNavTab.classList.remove('active')
      cycleJobsContainer.classList.add('active', 'show');
      unpostedJobsContainer.classList.remove('active', 'show');
    },
    async handleJobsTable() {
      switch (this.dataTablesType) {
        case 0: {
          this.campaignTableNavigation();
          if (this.isCampaignDateRange) return;

          if (this.cycle && this.url) {
            await this.getCampaignJobsTable();
          }

          if (this.unpostedUrl) {
            await this.getUnpostedJobsTable();
          }

          break;
        }
        case 1: {
          await this.getSinglesJobsTable();
          break;
        }
        case 2: {
          await this.getSelectJobsTable();
          break;
        }
        default:
          break;
      }
    },
    tBodyEventListener(name) {
      const tBody = document.getElementById(name);
      if (tBody) {
        tBody.addEventListener('click', this.handleJobPossibleClicks);
      }
    },
    handleJobPossibleClicks(ev) {
      const selectedModal = ev.target.className;

      if (selectedModal.includes('show-job-description')) {
        this.jobDescriptionData = {
          'division_id': ev.target.attributes['data-division-id'].value,
          'job_id': ev.target.attributes['data-job-id'].value,
          'job_name': ev.target.attributes['data-job-name'].value,
          'req_name': ev.target.attributes['data-req-name'].value,
        }

        if (this.dataTablesType === 0) {
          const spendLimit = document.getElementById(`spend-limit-${ev.target.attributes['data-job-id'].value}`);
          if (this.isCampaignDateRange) {
            this.jobDescriptionData.cycle_id = ev.target.attributes['data-cycle-id'].value;
            this.jobDescriptionData.ratio = ev.target.attributes['data-ratio'].value;
          } else {
            this.jobDescriptionData.cycle_id = this.cycle.campaign_cycle_id;
            this.jobDescriptionData.ratio = this.cycle.ratio;
          }

          if (spendLimit !== null) {
            if (!isNaN(parseFloat(spendLimit.getAttribute('data-spend-limit')))) {
              this.jobDescriptionData.spend_limit = parseFloat(spendLimit.getAttribute('data-spend-limit'));
            }
          }
        }

        this.showJobDesc = true;
      } else if (selectedModal.includes('show-post-job') && this.dataTablesType === 2) {
        this.jobActionData = {
          'division_id': ev.target.attributes['data-division-id'].value,
          'recruiter_id': this.userState.navBarData.recruiterId,
          'job_id': ev.target.attributes['data-job-id'].value,
          'user_name': this.userState.navBarData.firstName + ' ' + this.userState.navBarData.lastName,
          'user_email': this.userState.navBarData.email
        }
        this.jobAction = JobActionEnum.resume;
        this.showJobLegacyAction = true;
      } else if (selectedModal.includes('show-stop-job') && this.dataTablesType === 2) {
        this.jobActionData = {
          'division_id': ev.target.attributes['data-division-id'].value,
          'recruiter_id': this.userState.navBarData.recruiterId,
          'job_id': ev.target.attributes['data-job-id'].value,
          'user_name': this.userState.navBarData.firstName + ' ' + this.userState.navBarData.lastName,
          'user_email': this.userState.navBarData.email
        }
        this.jobAction = JobActionEnum.expire;
        this.showJobLegacyAction = true;
      } else if (selectedModal.includes('show-post-job')) {
        this.jobActionData = {
          'campaign_id': this.cycle.campaign_id,
          'cycle_id': this.cycle.campaign_cycle_id,
          'division_id': ev.target.attributes['data-division-id'].value,
          'recruiter_id': this.userState.navBarData.recruiterId,
          'job_id': ev.target.attributes['data-job-id'].value,
          'user_name': this.userState.navBarData.firstName + ' ' + this.userState.navBarData.lastName,
          'user_email': this.userState.navBarData.email
        }
        this.jobAction = JobActionEnum.resume;
        this.showJobAction = true;
      } else if (selectedModal.includes('show-stop-job')) {
        this.jobActionData = {
          'campaign_id': this.cycle.campaign_id,
          'cycle_id': this.cycle.campaign_cycle_id,
          'division_id': ev.target.attributes['data-division-id'].value,
          'recruiter_id': this.userState.navBarData.recruiterId,
          'job_id': ev.target.attributes['data-job-id'].value,
          'user_name': this.userState.navBarData.firstName + ' ' + this.userState.navBarData.lastName,
          'user_email': this.userState.navBarData.email
        }
        this.jobAction = JobActionEnum.expire;
        this.showJobAction = true;
      } else if (selectedModal.includes('show-repost')) {
        this.repostData = {
          'recruiter_id': this.userState.navBarData.recruiterId,
          'division_id': ev.target.attributes['data-division-id'].value,
          'job_id': ev.target.attributes['data-job-id'].value,
        }
        this.showRepost = true;
      } else if (selectedModal.includes('spend-limit-active')) {
        this.spendLimitData = {
          'division_id': ev.target.attributes['data-division-id'].value,
          'campaign_id': this.cycle.campaign_id,
          'cycle_id': this.cycle.campaign_cycle_id,
          'job_id': ev.target.attributes['data-job-id'].value,
          'spend_limit': ev.target.attributes['data-spend-limit'].value,
          'spend': ev.target.attributes['data-spend'].value,
          'ratio': this.cycle.ratio,
        };
        this.showSpendLimit = true;
      }
    },
    confirmPostJob(jobId) {
      const jobButton = document.getElementById(`job-action-${jobId}`);
      const status = document.getElementById(`job-status-${jobId}`);
      const icon = document.getElementById(`job-status-icon-${jobId}`);

      status.textContent = 'In Progress';
      status.setAttribute('class', 'status-label in-progress-status');

      icon.innerHTML = `${this.displayInfoIcon(this.infoIcon.inProgress)}`;

      jobButton.setAttribute('class', 'btn btn-outline-danger btn-sm show-stop-job');
      jobButton.textContent = 'Stop Job';
    },
    confirmStopJob(jobId) {
      const jobButton = document.getElementById(`job-action-${jobId}`);
      const status = document.getElementById(`job-status-${jobId}`);
      const icon = document.getElementById(`job-status-icon-${jobId}`);

      status.textContent = 'Unposted';
      status.setAttribute('class', 'status-label inactive-status');
 
      icon.innerHTML = `${this.displayInfoIcon(this.infoIcon.stopped)}`;

      jobButton.setAttribute('class', 'btn btn-outline-primary btn-sm show-post-job');
      jobButton.textContent = 'Post Job';
    },
    closeJobDescModal() {
      this.jobDescriptionData = null;
      this.showJobDesc = false;
    },
    closeJobActionModal() {
      this.jobAction = '';
      this.jobActionData = null;
      this.showJobAction = false;
    },
    closeJobLegacyActionModal() {
      this.jobAction = '';
      this.jobActionData = null;
      this.showJobLegacyAction = false;
    },
    closeRepostModal() {
      this.repostData = null;
      this.showRepost = false;
    },
    closeSpendLimitModal() {
      this.spendLimitData = null;
      this.showSpendLimit = false;
    },
    truncateText(text, length) {
      if (!text) return 'undefined';
      return text.length > length ? `${text.substring(0, length)}...` : text;
    },
    displayInfoIcon(status) {
      return `
        <span
          class="fa-stack fa-1x"
          title=${status.tooltip}
          data-container="body"
          data-tooltip="tooltip"
        >
          <i class="far fa-info-circle fa-stack-1x reg-icon ${status.color}"></i>
          <i class="fas fa-info-circle fa-stack-1x solid-icon ${status.color}"></i>
        </span>`;
    },
    handleSpendLimitUpdate(newSpendLimit, spendLimitData){
      const { job_id } = spendLimitData;
      const formattedSpendLimit = parseFloat(newSpendLimit).toFixed(2);

      // update data to reflect when re opening the modal
      const spendLimitElementId = 'spend-limit-' + job_id;
      document.getElementById(spendLimitElementId).innerHTML ='$'+formattedSpendLimit;
      $('#' + spendLimitElementId).attr('data-spend-limit', formattedSpendLimit);
    },
    handleJobsTableDataChange(selectedCycleId, jobs){
      /* 
        - Remove the jobs that is not in the selected cycle,
        - Used  jobs.filter instead of DataTable().clear() because there are
          instances that another instance of loadCycleJobs is runnning 
          causing the table to be cleared out if we use DataTable().clear()
          while jobs are loading from another cycle
        - Will return empty array if its not the same cycle
      */
      const filteredJobs = jobs.filter(job => job.cycle_id === selectedCycleId)
      $('#campaign-job-dataTables').DataTable().rows.add(filteredJobs).draw();
      
      /* 
        terminate the recursion/ request whenever a
        sudden cycle/campaign/dateRange change has been done
      */
      return
    },
    handleJobsTableDuplicateValues(){
      const campJobTable = '#campaign-job-dataTables';

      const currentDataTableJobs = $(campJobTable).DataTable().data().toArray();

      // clear the data table before adding the uniqueJobs
      $(campJobTable).DataTable().clear();

      const uniqueJobsMap = new Map(currentDataTableJobs.map(job => [job.campaign_job_id, job]))
      const finalJobs = Array.from(uniqueJobsMap.values())

      $('#campaign-job-dataTables').DataTable().rows.add(finalJobs).draw();
    },
    async completeCycleJobsDataFetch() {
      $('#campaign-job-dataTables').DataTable().settings()[0].oLanguage.sEmptyTable = 'No data available in table';
      $('#campaign-job-dataTables').DataTable().draw();
    },
    async fetchCycleJobs(lastKey = '') {
      return $.ajax({
        type: 'GET',
        url: this.url + `&last_evaluated_key=${lastKey}`,
        headers: {
            Authorization: `Bearer ${store.methods.getJtToken()}`,
            'Content-Type': 'application/json',
          },
      });
    },
    async loadCycleJobsData(lastKey = '', retryCount = 5, aggregatedData = { totalJobs: 0, activeJobs: 0, pausedJobs: 0 }, lastJobId = -1, cycleId = '') {
      const self = this;
      try {
        const response = await self.fetchCycleJobs(lastKey);
        const { data } = response;
        const { last_evaluated_key, jobs } = data;

        // Since recursion is made with a Stack DSA(LIFO), note that lastJobId is the first job id of the job returned and vice versa
        if (jobs.length > 0) {
            if (jobs[jobs.length -1].campaign_job_id == lastJobId && lastJobId != -1) {
                jobs.pop();
            } else {
                lastJobId = jobs[0].campaign_job_id;
            }
        }

        const jobsCycleId = cycleId === '' ? this.cycleId : cycleId;
        const selectedCycleId  = store.methods.getCurrentCycleId();

        // check if the current loadCycleJobs cyle id is still matched with the selected cycle
        if ( selectedCycleId !== jobsCycleId || self.dateRangeUrl ) {
          this.handleJobsTableDataChange(selectedCycleId, jobs)
          return
        }

        $('#campaign-job-dataTables').DataTable().rows.add(jobs).draw(); 

        // aggregate the job counts
        aggregatedData.totalJobs += jobs.length;
        aggregatedData.activeJobs += jobs.filter(({ status }) => status === 'active').length;
        aggregatedData.pausedJobs += jobs.filter(({ status }) => status === 'paused').length;
        self.$emit('jobs-analytics-data', aggregatedData);

        /*
          - Check if there are any duplicate jobs 
          - Happens when user switches the same campaign / cycle twice too quickly
        */ 
        const { totalJobs } = aggregatedData
        const currentDataTableJobs = $('#campaign-job-dataTables').DataTable().data().toArray();
        
        if( totalJobs.length !== currentDataTableJobs.length ) {
          this.handleJobsTableDuplicateValues();
        }

        if (last_evaluated_key === '') {
          self.$emit('jobs-analytics-data', aggregatedData);
          this.completeCycleJobsDataFetch();
        } else {
          await this.loadCycleJobsData(last_evaluated_key, retryCount, aggregatedData, lastJobId, jobsCycleId);     
        }
      } catch (err) {
        console.error('loadCycleJobs error: ', err);

        if (retryCount > 0) {
          console.log(`Fetch Cycle Jobs retrying... ${retryCount} attempts left.`);
          await this.loadCycleJobsData(lastKey, retryCount - 1, aggregatedData, lastJobId, this.cycleId);
        } else {
          console.error(`Failed to load Cycle Jobs data after ${retryCount} retries.`);
          
          // load the data in the job analytics tile even if some request fails
          self.$emit('jobs-analytics-data', aggregatedData); 
        }
      }
    },
    async getCampaignJobsTable() {
      const self = this;
      const isStatusActive = self.cycle.status_id !== 3;
      const isSpendLimit = self.cycle.status_id === 2 && self.cycle.customer_spend_limit;
      const downloadableColumns = [0, 1, 2, 3, 4, 6, 7, 8, 9];

      // include the spend limit column for downloadable columns
      isSpendLimit ? downloadableColumns.splice(5, 0, 5) : downloadableColumns;
      // include the status column for downloadble columns
      isStatusActive ? downloadableColumns.push(10) : downloadableColumns;

      self.jobTable = $('#campaign-job-dataTables').DataTable({
        fixedHeader: true,
        iDisplayLength: 50,
        order: [[4, 'desc']],
        language: {
          searchPlaceholder: 'Enter Keyword',
          info: 'Showing _START_ - _END_ of _TOTAL_',
          lengthMenu: '_MENU_',
          paginate: {
            next: '<i class="fal fa-chevron-right"><span class="sr-only">Next</span></i>',
            previous:
              '<i class="fal fa-chevron-left"><span class="sr-only">Previous</span></i>',
          }
        },
        pagingType: 'simple_numbers',
        dom:
          "<'row'<'col-sm-6 d-flex align-items-baseline'f><'col-sm-6 d-flex align-items-baseline justify-content-end'B>>" +
          "t" +
          "<'row'<'col-sm-6 d-flex align-items-baseline'p><'col-sm-6 d-flex align-items-baseline justify-content-end'<'wp-xs-25 wp-sm-15'l><'wp-xs-50 wp-sm-25' i>>>",
        buttons: [
          {
            extend: 'excelHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm',
          },
          {
            extend: 'pdfHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'print',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'copyHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'csvHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm'
          },
        ],
        columnDefs: [{
          sorting: ['desc', 'asc'],
          className: 'align-middle',
          targets: ['_all']
        }],
        columns: [
          { // col 0 = job_id
            data: 'campaign_job_id',
            visible: false
          },
          { // col 1 = job_name
            data: 'job_name',
            className: 'col-job-title align-middle',
            render(data, type, row) {
              if (type === 'export') return data;
              if (type === 'display') {
                return `<a
                          class="job-title text-primary show-job-description"
                          href="#"
                          data-toggle="modal"
                          data-target="#job-description-modal"
                          data-division-id="${row['division_id']}"
                          data-job-id="${row['campaign_job_id']}"
                          data-job-name="${data}"
                          data-req-name="${row['req_name']}"
                          data-tooltip="tooltip"
                          title="${data}"
                        >
                          ${self.truncateText(data, isSpendLimit ? 35 : 45)}
                        </a>
                        <p class="text-req-name">(${row['req_name']})</p>`;
              }

              return `${data} (${row['req_name']})`;
            },
          },
          { // col 2 = req_name
            data: 'req_name',
            visible: false
          },
          { // col 3 = location
            data: 'location',
            render(data, type, row) {
              if (type === 'export') return data;
              return `<span
                        data-tooltip="tooltip"
                        title="${data}"
                      >
                        ${self.truncateText(data, 16)}
                      </span>`;
            },
          },
          { // col 4 = spend
            data: 'spent',
            render(data, type, row) {
              return numeral(data / self.cycle.ratio).format('$0,0.00');
            },
            type: 'html-num-fmt',
            width: '5%',
          },
          { // col 5 = spend limit
            data: 'spend_limit',
            render(data, type, row) {
              const selectedLimit = data !== null ? data : self.cycle.job_spend_limit;
              const spendLimit = selectedLimit !== null ? selectedLimit / self.cycle.ratio : null;

              if (type === 'export') return spendLimit !== null ? numeral(spendLimit).format('$0,0.00') : '';
              if (type === 'display') {
                if (!data && row['status'] === 'inactive') {
                  return `<span id="spend-limit-${row['campaign_job_id']}" class="spend-limit-disabled">No Target</span>`;
                }
 
                return `
                        <span
                          id="spend-limit-${row['campaign_job_id']}"
                          class="spend-limit-active text-primary"
                          data-toggle="modal"
                          data-target="#spend-limit-modal"
                          data-division-id="${row['division_id']}"
                          data-job-id="${row['campaign_job_id']}"
                          data-spend-limit="${data ? (spendLimit !== null ? numeral(spendLimit).format('0.00') : '') : ''}"
                          data-spend="${numeral(row['spent'] / self.cycle.ratio).format('0.00')}"
                        >
                          ${data ? (spendLimit !== null ? numeral(spendLimit).format('$0.00') : '') : 'No Target'}
                        </span>`;
              }
              return spendLimit;
            },
            visible: isSpendLimit,
          },
          { // col 6 = clicks
            data: 'clicks',
            width: '5%',
          },
          // { // col 7 = CPC
          //   data: 'clicks',
          //   render(data, type, row) {
          //     if (data < 1 && type === 'display') return '<span class="pl-2">—</span>';

          //     if (data < 1)
          //       return '';
          //     else
          //       return numeral((row['spent'] / self.cycle.ratio) / data).format('$0,0.00');
          //   },
          //   type: 'num-fmt',
          //   width: '5%',
          // },
          { // col 8 = apps
            data: 'apps',
            render(data, type, row) {
              // sort the column base on the raw data
              if (type === 'sort' || type === 'type') { return data; }

              const start_date = moment(self.cycle.start_date).format('L');
              const end_date = moment(self.cycle.end_date).format('L');

              if (self.isCompanyEAEnabled && data > 0 && type === 'display') {
                return `<a
                          class="apps-link text-reset"
                          href="${process.env.VUE_APP_CANDIDATE_MANAGER_URL}/source/job_applicants?jobId=${row['campaign_job_id']}&divisionId=${row['division_id']}&daterange=${start_date}-${end_date}"
                          target="_blank"
                        >
                          <u>${data}</u>
                        </a>`
              }
              return data;
            },
            width: '7%',
          },
          { // col 9 = CPA
            data: 'apps',
            render(data, type, row) {
              if (data < 1 && type === 'display') return '<span class="pl-2">—</span>';

              if (data < 1)
                return '';
              else
                return numeral((row['spent'] / self.cycle.ratio) / data).format('$0,0.00');
            },
            type: 'num-fmt',
            width: '3%',
          },
          { // col 10 = status
            data: 'status',
            render(data, type, row) {
              switch (data) {
                case 'active':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label active-status">Active</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.active)}</span>`;
                case 'paused':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label paused-status">Paused</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.generalPause)}</span>`;
                case 'inactive':
                  if (row['is_open'] || (!row['is_open'] && row['has_activity'])) {
                    return `<span id="job-status-${row['campaign_job_id']}" class="status-label inactive-status">Unposted</span>
                            <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.inactiveCampaign)}</span>`;
                  }
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label inactive-status">Expired</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.closed)}</span>`;
                default:
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label active-status">Active</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.active)}</span>`;
              }
            },
            visible: isStatusActive,
            width: '10%',
          },
          { // col 11 = action
            data: 'status',
            render: function (data, type, row) {
              if (data === 'active' || data === 'paused' || data === 'in_progress') {
                return `<button
                            id="job-action-${row['campaign_job_id']}"
                            class="btn btn-outline-danger btn-sm show-stop-job"
                            data-toggle="modal"
                            data-target="#action-job-modal"
                            data-division-id="${row['division_id']}"
                            data-job-id="${row['campaign_job_id']}"
                          >
                            Stop Job
                          </button>`;
              } else if (
                (data === 'inactive' || data === 'pending_delete') &&
                (row['is_open'] || (!row['is_open'] && row['has_activity']))) {
                return `<button
                          id="job-action-${row['campaign_job_id']}"
                          class="btn btn-outline-primary btn-sm show-post-job"
                          data-toggle="modal"
                          data-target="#action-job-modal"
                          data-division-id="${row['division_id']}"
                          data-job-id="${row['campaign_job_id']}"
                        >
                          Post Job
                        </button>`;
              }
              return '<i class="pl-2">—</i>';
            },
            visible: isStatusActive,
            width: '10%',
          },
        ]
      });
      $('#campaign-job-dataTables').DataTable().settings()[0].oLanguage.sEmptyTable = 'Loading';
      $('#campaign-job-dataTables').DataTable().draw();
      self.tBodyEventListener('jobs-tbody');

      (async () => {
        try {
          await self.loadCycleJobsData();
        } catch (error) {
          console.error('Error initializing campaigns data: ', error);
        }
      })();
    },
    async completeUnpostedJobsDataFetch() {
      $('#unposted-jobs-dataTables').DataTable().settings()[0].oLanguage.sEmptyTable = 'No data available in table';
      $('#unposted-jobs-dataTables').DataTable().draw();
    },
    async fetchUnpostedJobs(skip, take) {
      return $.ajax({
        type: 'GET',
        url: this.unpostedUrl + `?skip=${skip}&take=${take}`,
        headers: {
            Authorization: `Bearer ${store.methods.getJtToken()}`,
            'Content-Type': 'application/json',
          },
      });
    },
    async loadUnpostedJobs(skip = 0, retryCount = 5) {
      const self = this;
      try { 
        const take = 200; // number of jobs to load (active + unposted but will be filtered by unposted)
        const response = await self.fetchUnpostedJobs(skip, take);
        const { has_more_jobs_to_load, unposted_jobs } = response.data;
      
        skip+=take; // update skip to skip the last set of jobs that were fetched
 
        if (unposted_jobs.length > 0) {
          $('#unposted-jobs-dataTables').DataTable().rows.add(unposted_jobs).draw();
        }

        if (!has_more_jobs_to_load) {
          self.completeUnpostedJobsDataFetch();
          return;
        }

        if (has_more_jobs_to_load) {
          await self.loadUnpostedJobs(skip);
        }
      } catch (err) {
        console.error('loadUnpostedJobs error: ', err);

        if (retryCount > 0) {
          console.log(`Fetch Unposted Jobs retrying... ${retryCount} attempts left.`);
          await self.loadUnpostedJobs(skip, retryCount - 1);
        } else {
          console.error(`Failed to load Unposted Jobs data after ${retryCount} retries.`);
        }
      }
    },
    async getUnpostedJobsTable() {
      const self = this;

      self.unpostedJobTable = $('#unposted-jobs-dataTables').DataTable({
        autoWidth: false,
        fixedHeader: true,
        iDisplayLength: 50,
        language: {
          searchPlaceholder: 'Enter Keyword',
          info: 'Showing _START_ - _END_ of _TOTAL_',
          lengthMenu: '_MENU_',
          paginate: {
            next: '<i class="fal fa-chevron-right"><span class="sr-only">Next</span></i>',
            previous:
              '<i class="fal fa-chevron-left"><span class="sr-only">Previous</span></i>',
          }
        },
        pagingType: 'simple_numbers',
        dom:
          "<'row'<'col-sm-6 d-flex align-items-baseline'f><'col-sm-6 d-flex align-items-baseline justify-content-end'>>" +
          "t" +
          "<'row'<'col-sm-6 d-flex align-items-baseline'p><'col-sm-6 d-flex align-items-baseline justify-content-end'<'wp-xs-25 wp-sm-15'l><'wp-xs-50 wp-sm-25' i>>>",
        columnDefs: [{
          className: 'align-middle',
          targets: ['_all']
        }],
        columns: [
          { // col 0
            data: null,
            visible: false
          },
          { // col 1 = job_id
            data: 'job_id',
            visible: false
          },
          { // col 2 = job_name
            data: 'job_name',
            className: 'col-job-title align-middle',
            render(data, type, row) {
              if (type === 'display') {
                return `
                  <a
                    class="job-title"
                    href="${process.env.VUE_APP_JOB_MANAGER_URL}/details/${row['job_id']}"
                    data-req-name="${row['req_name']}"
                    data-tooltip="tooltip"
                    title="${data}"
                    target="_blank"
                  >
                    ${self.truncateText(data, 75)}
                  </a>
                  <p class="text-req-name">(${row['req_name']})</p>`;
              }

              return data;
            },
            width: '53%'
          },
          { // col 3 = location
            data: 'location',
            render(data, type, row) {
              return `${self.truncateText(data, 30)}`;
            },
            width: '20%'
          },
          { // col 4 = status
            data: 'status',
            render(data, type, row) {
              switch (data) {
                case 'in_progress':
                  return `<span id="job-status-${row['job_id']}" class="status-label in-progress-status">In Progress</span>
                          <span id="job-status-icon-${row['job_id']}">${self.displayInfoIcon(self.infoIcon.inProgress)}</span>`;
                default:
                  return `<span id="job-status-${row['job_id']}" class="status-label inactive-status">Unposted</span>
                          <span id="job-status-icon-${row['job_id']}">${self.displayInfoIcon(self.infoIcon.inactiveUnposted)}</span>`;
              }
            },
            width: '17%'
          },
          { // col 5 = action
            data: 'status',
            render(data, type, row) {
              switch (data) {
                case 'unposted':
                  return `<button
                            id="job-action-${row['job_id']}"
                            class="btn btn-outline-primary btn-sm show-post-job"
                            data-toggle="modal"
                            data-target="#action-job-modal"
                            data-division-id="${row['division_id']}"
                            data-job-id="${row['job_id']}"
                          >
                            Post Job
                          </button>`;
                default:
                  return `<button
                            id="job-action-${row['job_id']}"
                            class="btn btn-outline-danger btn-sm show-stop-job"
                            data-toggle="modal"
                            data-target="#action-job-modal"
                            data-division-id="${row['division_id']}"
                            data-job-id="${row['job_id']}"
                          >
                            Stop Job
                          </button>`;
              }
            },
            width: '10%'
          },
          { // col 6 = req_name
            data: 'req_name',
            visible: false
          }
        ]
      });
      
      $('#unposted-jobs-dataTables').DataTable().settings()[0].oLanguage.sEmptyTable = 'Loading';
      $('#unposted-jobs-dataTables').DataTable().draw();
      self.tBodyEventListener('jobs-unposted-tbody');
      self.showUnpostedTable = true;

      (async () => {
        try {
          await self.loadUnpostedJobs();
        } catch (error) {
          console.error('Error initializing campaigns data: ', error);
        }
      })();
    },
    async completeSelectJobsDataFetch() {
      $('#select-jobs-dataTables').DataTable().settings()[0].oLanguage.sEmptyTable = 'No data available in table';
      $('#select-jobs-dataTables').DataTable().draw();
    },
    async fetchSelectJobs(lastPage = 1) {
      return $.ajax({
        type: 'GET',
        url: this.url + `&page=${lastPage}`,
        headers: {
            Authorization: `Bearer ${store.methods.getJtToken()}`,
            'Content-Type': 'application/json',
          },
      });
    },
    async loadSelectJobs(lastPage = 0, retryCount = 5) {
      const self = this;
      try {
        const response = await self.fetchSelectJobs(lastPage);
        const { has_more_jobs, unposted_jobs } = response;
        lastPage++;

        if (unposted_jobs.length > 0) {
          $('#select-jobs-dataTables').DataTable().rows.add(unposted_jobs).draw();
        }

        if (!has_more_jobs) {
          self.completeSelectJobsDataFetch();
          return;
        }

        if (has_more_jobs) {
          await self.loadSelectJobs(lastPage);
        }
      } catch (err) {
        console.error('loadSelectJobs error: ', err);

        if (retryCount > 0) {
          console.log(`Fetch Unposted Jobs retrying... ${retryCount} attempts left.`);
          await self.loadSelectJobs(lastPage, retryCount - 1);
        } else {
          console.error(`Failed to load Select Jobs data after ${retryCount} retries.`);
        }
      }
    },
    async getDateRangeJobsTable() {
      const self = this;

      self.jobTable = $('#campaign-job-dataTables').DataTable({
        ajax: {
          url: self.dateRangeUrl,
          type: 'GET',
          headers: {
            Authorization: `Bearer ${store.methods.getJtToken()}`,
            'Content-Type': 'application/json',
          },
          dataSrc(json) {
            const dateRangeAnalyticsData = {};

            dateRangeAnalyticsData.totalJobs = json.length;
            dateRangeAnalyticsData.activeJobs = json.filter(({ status }) => status === 'active').length;
            dateRangeAnalyticsData.pausedJobs = json.filter(({ status }) => status === 'paused').length;
            dateRangeAnalyticsData.clicks = json.reduce((n, { clicks }) => n + clicks, 0);
            dateRangeAnalyticsData.apps = json.reduce((n, { apps }) => n + apps, 0);
            dateRangeAnalyticsData.spend = json.reduce((n, { spent, ratio }) => n + (spent / ratio), 0);

            self.$emit('date-range-analytics-data', dateRangeAnalyticsData);
            return json;
          },
        },
        fixedHeader: true,
        iDisplayLength: 50,
        order: [[4, 'desc']],
        columnDefs: [{
          sorting: ['desc', 'asc'],
          className: 'align-middle',
          targets: ['_all']
        }],
        language: {
          searchPlaceholder: 'Enter Keyword',
          info: 'Showing _START_ - _END_ of _TOTAL_',
          lengthMenu: '_MENU_',
          paginate: {
            next: '<i class="fal fa-chevron-right"><span class="sr-only">Next</span></i>',
            previous:
              '<i class="fal fa-chevron-left"><span class="sr-only">Previous</span></i>',
          }
        },
        pagingType: 'simple_numbers',
        dom:
          "<'row'<'col-sm-6 d-flex align-items-baseline'f><'col-sm-6 d-flex align-items-baseline justify-content-end'B>>" +
          "t" +
          "<'row'<'col-sm-6 d-flex align-items-baseline'p><'col-sm-6 d-flex align-items-baseline justify-content-end'<'wp-xs-25 wp-sm-15'l><'wp-xs-50 wp-sm-25' i>>>",
        buttons: [
          {
            extend: 'excelHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: [0, 1, 2, ':visible']
            },
            className: 'btn btn-outline-primary btn-sm',
          },
          {
            extend: 'pdfHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: [0, 1, 2, ':visible']
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'print',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: [0, 1, 2, ':visible']
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'copyHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: [0, 1, 2, ':visible']
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'csvHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: [0, 1, 2, ':visible']
            },
            className: 'btn btn-outline-primary btn-sm'
          },
        ],
        columns: [
          { // col 0 = job_id
            data: 'campaign_job_id',
            visible: false
          },
          { // col 1 = job_name
            data: 'job_name',
            className: 'col-job-title align-middle',
            render(data, type, row) {
              if (type === 'export') return data;
              if (type === 'display') {
                return `<a
                          class="job-title text-primary show-job-description"
                          href="#"
                          data-toggle="modal"
                          data-target="#job-description-modal"
                          data-division-id="${row['division_id']}"
                          data-cycle-id="${row['campaign_cycle_id']}"
                          data-ratio="${row['ratio']}"
                          data-job-id="${row['campaign_job_id']}"
                          data-job-name="${data}"
                          data-req-name="${row['req_name']}"
                          data-tooltip="tooltip"
                          title="${data}"
                        >
                          ${self.truncateText(data, 75)}
                        </a>
                        <p class="text-req-name">(${row['req_name']})</p>`;
              }

              return `${data} (${row['req_name']})`;
            },
            width: '43%'
          },
          { // col 2 = req_name
            data: 'req_name',
            visible: false
          },
          { // col 3 = location
            data: 'location',
            render(data, type, row) {
              if (type === 'export') return data;
              return `${self.truncateText(data, 20)}`;
            },
            width: '15%'
          },
          { // col 4 = spend
            data: 'spent',
            render(data, type, row) {
              return numeral(data / row['ratio']).format('$0,0.00');
            },
            type: 'html-num-fmt',
            width: '5%',
          },
          { // col 5 = spend limit
            data: null,
            visible: false
          },
          { // col 6 = clicks
            data: 'clicks',
            width: '5%',
          },
          // { // col 7 = CPC
          //   data: 'clicks',
          //   render(data, type, row) {
          //     if (data < 1 && type === 'display') return '<span class="pl-2">—</span>';

          //     if (data < 1)
          //       return '';
          //     else
          //       return numeral((row['spent'] / row['ratio']) / data).format('$0,0.00');
          //   },
          //   type: 'num-fmt',
          //   width: '5%'
          // },
          { // col 8 = apps
            data: 'apps',
            render(data, type, row) {
              // sort the column base on the raw data
              if (type === 'sort' || type === 'type') { return data; }

              const url = self.dateRangeUrl;
              const params = new URLSearchParams(url);
              const start_date = params.get('start_date');
              const end_date = params.get('end_date');

              if (self.isCompanyEAEnabled && data > 0 && type === 'display') {
                return `<a
                          class="apps-link text-reset"
                          href="${process.env.VUE_APP_CANDIDATE_MANAGER_URL}/source/job_applicants?jobId=${row['campaign_job_id']}&divisionId=${row['division_id']}&daterange=${moment(start_date).format('L')}-${moment(end_date).format('L')}"
                          target="_blank"
                        >
                          <u>${data}</u>
                        </a>`
              }
              return data;
            },
            width: '8%'
          },
          { // col 9 = CPA
            data: 'apps',
            render(data, type, row) {
              if (data < 1 && type === 'display') return '<span class="pl-2">—</span>';

              if (data < 1)
                return '';
              else
                return numeral((row['spent'] / row['ratio']) / data).format('$0,0.00');
            },
            type: 'num-fmt',
            width: '5%',
          },
          { // col 10 = status
            data: 'status',
            render(data, type, row) {
              if (row['cycle_status_id'] === 3) return '—';
              switch (data) {
                case 'active':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label active-status">Active</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.active)}</span>`;
                case 'paused':
                case 'inactive':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label inactive-status">Inactive</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.inactiveDateRange)}</span>`;
                case 'in_progress':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label in-progress-status">In Progress</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.inProgress)}</span>`;
                case 'pending_delete':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label in-progress-status">Removing</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.pendingDelete)}</span>`;
                default:
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label active-status">Active</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.active)}</span>`;
              }
            },
            width: '17%',
          },
          { // col 11 = action
            data: null,
            visible: false
          },
        ]
      });
    },
    async getSinglesJobsTable() {
      const self = this;
      const downloadableColumns = [0, 1, 2, 3, 4, 5, 6, 7];

      self.jobTable = $('#singles-job-dataTables').DataTable({
        ajax: {
          url: self.url,
          type: 'GET',
          headers: {
            Authorization: `Bearer ${store.methods.getJtToken()}`,
            'Content-Type': 'application/json',
          },
          dataSrc(json) {
            return json;
          }
        },
        fixedHeader: true,
        iDisplayLength: 50,
        order: [[4, 'desc']],
        language: {
          searchPlaceholder: 'Enter Keyword',
          info: 'Showing _START_ - _END_ of _TOTAL_',
          lengthMenu: '_MENU_',
          paginate: {
            next: '<i class="fal fa-chevron-right"><span class="sr-only">Next</span></i>',
            previous:
              '<i class="fal fa-chevron-left"><span class="sr-only">Previous</span></i>',
          }
        },
        pagingType: 'simple_numbers',
        dom:
          "<'row'<'col-sm-6 d-flex align-items-baseline'f><'col-sm-6 d-flex align-items-baseline justify-content-end'B>>" +
          "t" +
          "<'row'<'col-sm-6 d-flex align-items-baseline'p><'col-sm-6 d-flex align-items-baseline justify-content-end'<'wp-xs-25 wp-sm-15'l><'wp-xs-50 wp-sm-25' i>>>",
        buttons: [
          {
            extend: 'excelHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm',
          },
          {
            extend: 'pdfHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'print',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'copyHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm'
          },
          {
            extend: 'csvHtml5',
            title: 'Programmatic Dashboard - JobTarget',
            exportOptions: {
              columns: downloadableColumns,
              orthogonal: 'export',
            },
            className: 'btn btn-outline-primary btn-sm'
          },
        ],
        columnDefs: [{
          sorting: ["desc", "asc"],
          className: 'align-middle',
          targets: ['_all']
        }],
        columns: [
          { // col 0 = job_id
            data: 'campaign_job_id',
            visible: false
          },
          { // col 1 = job_name
            data: 'job_name',
            className: 'col-job-title align-middle',
            render(data, type, row) {
              if (type === 'display') {
                return `<a
                          class="job-title text-primary show-job-description"
                          href="#"
                          data-toggle="modal"
                          data-target="#job-description-modal"
                          data-division-id="${row['division_id']}"
                          data-job-id="${row['campaign_job_id']}"
                          data-job-name="${data}"
                          data-req-name="${row['req_name']}"
                          data-tooltip="tooltip"
                          title="${data}"
                        >
                          ${self.truncateText(data, 60)}
                        </a>
                        <p class="text-req-name">(${row['req_name']})</p>`;
              }

              return data;
            },
            width: '35%',
          },
          { // col 2 = req_name
            data: 'req_name',
            visible: false
          },
          { // col 3 = location
            data: 'location',
            render(data, type, row) {
              if (type === 'export') return data;

              return `<span
                        data-tooltip="tooltip"
                        title="${data}"
                      >
                        ${self.truncateText(data, 20)}
                      </span>`;
            },
            width: '15%',
          },
          { // col 4 = clicks
            data: 'clicks',
            width: '5%',
          },
          { // col 5 = apps
            data: 'apps',
            render(data, type, row) {
              // sort the column base on the raw data
              if (type === 'sort' || type === 'type') { return data; }

              const start_date = self.jobDateRange.startDate;
              const end_date = self.jobDateRange.endDate;
              
              if (self.isCompanyEAEnabled && data > 0 && type === 'display') {
                return `<a
                          class="apps-link text-reset"
                          href="${process.env.VUE_APP_CANDIDATE_MANAGER_URL}/source/job_applicants?jobId=${row['campaign_job_id']}&divisionId=${row['division_id']}&daterange=${moment(start_date).format('L')}-${moment(end_date).format('L')}"
                          target="_blank"
                        >
                          <u>${data}</u>
                        </a>`
              }
              return data;
            },
            width: '7%',
          },
          { // col 6 = posted date
            data: 'post_date',
            render(data, type, row) {
              const date = data === null ? 'N/A' : moment(data).format('MM/DD/YY');
              if (type === 'export') return date;
              if (type === 'display') {
                return `<div class="icon-container">
                          <span class="post-date">${date}</span>
                          ${self.displayInfoIcon(self.infoIcon.postedDate)}
                        </div>`;
              }
              return `${data}`;
            },
            width: '13%',
          },
          { // col 7 = expire_date
            data: 'expire_date',
            render(data, type, row) {
              const date = data === null ? 'N/A' : moment(data).format('MM/DD/YY');
              if (type === 'export') return date;
              if (type === 'display') {
                return `<div class="icon-container">
                          <span class="expire-date">${date}</span>
                        </div>`;
              }
              return `${data}`;
            },
            width: '13%',
          },
          { // col 8 = status
            data: 'status',
            render(data, type, row) {
              switch (data) {
                case 'active':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label active-status">Active</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.activeSingles)}</span>`;
                case 'paused':
                case 'inactive':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label inactive-status">Inactive</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.inactiveSingles)}</span>`;
                case 'in_progress':
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label in-progress-status">In Progress</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.inProgress)}</span>`;
                default:
                  return `<span id="job-status-${row['campaign_job_id']}" class="status-label active-status">Active</span>
                          <span id="job-status-icon-${row['campaign_job_id']}">${self.displayInfoIcon(self.infoIcon.activeSingles)}</span>`;
              }
            },
            width: '17%',
          },
          { // col 9 = action
            data: 'status',
            render(data, type, row) {
              if (data === 'inactive') {
                return `<button
                          class="btn btn-outline-primary show-repost btn-sm"
                          data-toggle="modal"
                          data-target="#repost-job-modal"
                          data-division-id="${row['division_id']}"
                          data-job-id="${row['campaign_job_id']}"
                        >
                          Repost
                        </button>`;
              } else {
                return '—';
              }
            },
            width: '10%',
          },
        ]
      });
    },
    async getSelectJobsTable() {
      const self = this;

      self.jobTable = $('#select-jobs-dataTables').DataTable({
        autoWidth: false,
        fixedHeader: true,
        iDisplayLength: 50,
        language: {
          searchPlaceholder: 'Enter Keyword',
          info: 'Showing _START_ - _END_ of _TOTAL_',
          lengthMenu: '_MENU_',
          paginate: {
            next: '<i class="fal fa-chevron-right"><span class="sr-only">Next</span></i>',
            previous:
              '<i class="fal fa-chevron-left"><span class="sr-only">Previous</span></i>',
          }
        },
        pagingType: 'simple_numbers',
        dom:
          "<'row'<'col-sm-6 d-flex align-items-baseline'f><'col-sm-6 d-flex align-items-baseline justify-content-end'>>" +
          "t" +
          "<'row'<'col-sm-6 d-flex align-items-baseline'p><'col-sm-6 d-flex align-items-baseline justify-content-end'<'wp-xs-25 wp-sm-15'l><'wp-xs-50 wp-sm-25' i>>>",
        columnDefs: [{
          className: 'align-middle',
          targets: ['_all']
        }],
        columns: [
          { // col 0
            data: null,
            visible: false
          },
          { // col 1 = job_id
            data: 'job_id',
            visible: false
          },
          { // col 2 = job_name
            data: 'job_name',
            className: 'col-job-title align-middle',
            render(data, type, row) {
              if (type === 'display') {
                return `
                  <a
                    class="job-title"
                    href="${process.env.VUE_APP_JOB_MANAGER_URL}/details/${row['job_id']}"
                    data-tooltip="tooltip"
                    title="${data}"
                    target="_blank"
                  >
                    ${self.truncateText(data, 75)}
                  </a>
                  <p class="text-req-name">(${row['req_name']})</p>`;
              }

              return data;
            },
            width: '53%'
          },
          { // col 3 = location
            data: 'location',
            render(data, type, row) {
              return `${self.truncateText(data, 30)}`;
            },
            width: '20%'
          },
          { // col 4 = status
            data: 'status',
            render(data, type, row) {
              switch (data) {
                case 'in_progress':
                  return `<span id="job-status-${row['job_id']}" class="status-label in-progress-status">In Progress</span>
                          <span id="job-status-icon-${row['job_id']}">${self.displayInfoIcon(self.infoIcon.inProgress)}</span>`;
                default:
                  return `<span id="job-status-${row['job_id']}" class="status-label inactive-status">Unposted</span>
                          <span id="job-status-icon-${row['job_id']}">${self.displayInfoIcon(self.infoIcon.inactiveUnposted)}</span>`;
              }
            },
            width: '17%'
          },
          { // col 5 = action
            data: 'status',
            render(data, type, row) {
              switch (data) {
                case 'unposted':
                  return `<button
                            id="job-action-${row['job_id']}"
                            class="btn btn-outline-primary btn-sm show-post-job"
                            data-toggle="modal"
                            data-target="#action-job-legacy-modal"
                            data-division-id="${row['division_id']}"
                            data-job-id="${row['job_id']}"
                          >
                            Post Job
                          </button>`;
                default:
                  return `<button
                            id="job-action-${row['job_id']}"
                            class="btn btn-outline-danger btn-sm show-stop-job"
                            data-toggle="modal"
                            data-target="#action-job-legacy-modal"
                            data-division-id="${row['division_id']}"
                            data-job-id="${row['job_id']}"
                          >
                            Stop Job
                          </button>`;
              }
            },
            width: '10%'
          }
        ]
      });

      $('#select-jobs-dataTables').DataTable().settings()[0].oLanguage.sEmptyTable = 'Loading';
      $('#select-jobs-dataTables').DataTable().draw();
      self.tBodyEventListener('jobs-tbody');
      
      (async () => {
        try {
          await self.loadSelectJobs();
        } catch (error) {
          console.error('Error on loading jobs: ', error);
        }
      })();
    }
  }
}
</script>

<style>
#table-header-container {
  margin-left: 0;
  margin-bottom: 24px;
}

.table-text-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #212529;
}

#jobs-dataTables_wrapper,
#cycle-jobs-container,
#unposted-jobs-container {
  padding-left: 0;
  padding-right: 0;
}

table.dataTable th {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
}

table.dataTable td {
  font-size: 14px;
  line-height: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.col-job-title {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.text-req-name {
  font-size: 12px;
  line-height: 18px;
  color: #6C757D;
  margin-bottom: 0;
}

.show-job-description,
.show-stop-job,
.show-post-job,
.spend-limit-active,
.apps-link {
  cursor: pointer;
}

.job-title,
.spend-limit-active,
.spend-limit-disabled {
  font-weight: 500;
}

.job-title:hover,
.apps-link {
  text-decoration: none;
}

.icon-container {
  display: flex;
}

.post-date {
  width: 75px;
}

.solid-icon {
  display: none;
}

.fa-stack:hover .solid-icon {
  display: block;
}

.fa-stack:hover .reg-icon {
  display: none;
}

/* .green-icon {
  color: #11807A;
} */

.tooltip-inner {
  color: #000;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #fff;
}

.fa-stack {
  width: 1em !important;
}
</style>